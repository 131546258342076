import { Box, Center, Container, Heading, Text } from '@chakra-ui/react';
import { SEO } from '@feature/seo';
import { FC } from 'react';

export const Head = () => <SEO title='Home' />;

const IndexPage: FC = () => {
  return (
    <>
      <SEO title='Home' />

      <Box height='100vh'>
        <Center h='100%'>
          <Container>
            <Heading as='h1' mb='4' fontSize='4rem'>
              STREETFOCUS
            </Heading>

            <Text fontSize='xl'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              eget est placerat, sollicitudin nulla quis, efficitur dolor. Cras
              in egestas purus. Ut nec odio ipsum. In turpis urna, tristique vel
              pretium non, aliquet ut magna. Orci varius natoque penatibus et
              magnis dis parturient montes, nascetur ridiculus mus.
            </Text>
          </Container>
        </Center>
      </Box>
    </>
  );
};

export default IndexPage;
